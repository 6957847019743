.table-search {
    height: 30px;
    width: 400px;
    display: inline-block;
    max-width: calc(100% - 40px);
    position: relative;
    vertical-align: top;
}
.table-search-items {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    background: #f9f9f9;
    transition: all 0.2s;
    border: 1px solid #01acf1;
}
.show-special .table-search-items{
    border-radius: 20px 20px 0 0;
}

.table-search-item.icon{
    background-size: 15px;
    cursor: pointer;
    transition: all 0.5s;
}
.table-search-item.search{
    font-size: 18px;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    background: #f9f9f9;
    color: #474747;
    border-right: 1px solid #a7a6a6;
}
.table-search-item.special-search{
    background-size: 10px;
    cursor: pointer;
    transition: all 0.5s;
}
.show-special .table-search-item.special-search{
    transform: rotate(180deg);
}
.special-search-list{
    position: absolute;
    width: 100%;
    z-index: 5;
    top: 40px;
    height: fit-content;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    background: #f9f9f9;
}
.show-special .special-search-list{
    max-height: 800px;
}
.special-search-option {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 20px 20px 0;
    font-size: 16px;
    color: #01acf1;
    font-weight: 700;
    border-bottom: 1px solid #d8d8d8;
}
.special-search-option input {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    font-size: 18px;
    background: #f9f9f9;
}
.special-search-option .input{
    border-bottom: 1px solid #01acf1;
}
.special-search-btn {
    float: right;
    margin: 15px 20px;
}