html,body,div#root{
    height: 100%;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
    font-size: 14px;
}
img {
    max-width: 100%;
    display: block;
}
form{
    height: 100%;
}
.items {
    display: flex;
    justify-content: space-between;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }
input:focus{
    outline: unset;
}
@media screen and (max-width: 800px){
    html,body,div#root{
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
}
@import url('https://fonts.googleapis.com/css?family=Rubik:regular,bold,900,800,700,600,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap');

@font-face {
    font-family: 'Assistant';
    src: url('https://static.tickchak.co.il/fonts/Assistant-Bold.eot');
    src: url('https://static.tickchak.co.il/fonts/Assistant-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://static.tickchak.co.il/fonts/Assistant-Bold.ttf') format('truetype'),
    url('https://static.tickchak.co.il/fonts/Assistant-Bold.svg#Assistant-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('https://static.tickchak.co.il/fonts/Assistant-ExtraBold.eot');
    src: url('https://static.tickchak.co.il/fonts/Assistant-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('https://static.tickchak.co.il/fonts/Assistant-ExtraBold.ttf') format('truetype'),
    url('https://static.tickchak.co.il/fonts/Assistant-ExtraBold.svg#Assistant-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('https://static.tickchak.co.il/fonts/Assistant-ExtraLight.eot');
    src: url('https://static.tickchak.co.il/fonts/Assistant-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('https://static.tickchak.co.il/fonts/Assistant-ExtraLight.ttf') format('truetype'),
    url('https://static.tickchak.co.il/fonts/Assistant-ExtraLight.svg#Assistant-ExtraLight') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('https://static.tickchak.co.il/fonts/Assistant-Light.eot');
    src: url('https://static.tickchak.co.il/fonts/Assistant-Light.eot?#iefix') format('embedded-opentype'),
    url('https://static.tickchak.co.il/fonts/Assistant-Light.ttf') format('truetype'),
    url('https://static.tickchak.co.il/fonts/Assistant-Light.svg#Assistant-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {

    font-family: 'Assistant';
    src: url('https://static.tickchak.co.il/fonts/Assistant-Regular.eot');
    src: url('https://static.tickchak.co.il/fonts/Assistant-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://static.tickchak.co.il/fonts/Assistant-Regular.ttf') format('truetype'),
    url('https://static.tickchak.co.il/fonts/Assistant-Regular.svg#Assistant-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('https://static.tickchak.co.il/fonts/Assistant-SemiBold.eot');
    src: url('https://static.tickchak.co.il/fonts/Assistant-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('https://static.tickchak.co.il/fonts/Assistant-SemiBold.ttf') format('truetype'),
    url('https://static.tickchak.co.il/fonts/Assistant-SemiBold.svg#Assistant-SemiBold') format('svg');
    font-weight: 500;
    font-style: normal;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    font-family: Assistant;
    color: #484848;
}

a, a:hover, a:visited {
    text-decoration: none;
    color: inherit;
}

ul, li {
    list-style: none;
    display: block;
}

.pac-container { z-index: 100000}

.pac-container:after{
    content:none;
}
div#zsiq_float {
    display: none !important;
}
