.mailing-setting-hover {
    position: absolute;
    left: 4px;
    width: 0;
    height: 30px;
    top: 15px;
    background: #ffffff;
    transition: all 1s;
    overflow: hidden;
}
.mailingSettingHover:hover .mailing-setting-hover {
    width: 100px;
}
.m-s-h-action {
    height: 100%;
    width: 30px;
    background-size: 18px;
}