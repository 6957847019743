:root {

    /* Colors: */
    --unnamed-color-939191: #939191;
    --unnamed-color-484848: #484848;
    --unnamed-color-a0d11c: #A0D11C;
    --unnamed-color-43bae7: #43BAE7;
    --unnamed-color-9cb900: #9CB900;
}
.table-status{
    border-radius: 30px;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    padding: 0 10px;
    transition: all 200ms;
    position: relative;
    width: 110px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
}
.mini div[class*=' status-'].table-status{
    width: 16px;
    height: 16px;
    line-height: 7px;
    padding: 0;
    text-align: center;
    font-size: 15px;
}
.mini .not-mini div[class*=' status-'].table-status, .mini .col-details-title div[class*=' status-'].table-status{
    font-size: 10px;
    line-height: 20px;
    width: 80px;
    text-align: left;
    height: fit-content;
    padding: 0 10px;
}
.mini .col-details-title div[class*=' status-'].table-status{
    margin: auto;
}
.table-status.status-0, .mini .not-mini .table-status.status-0{
    border: #01acf1 3px solid;
    color: #01acf1;
    background: #01acf130;
}
.table-status.status-1, .mini .not-mini .table-status.status-1{
    border: #96CB1F 3px solid;
    color: #96CB1F;
    background: #E3EDD0;
}
.table-status.status-2, .mini .not-mini .table-status.status-2,
.table-status.status-7, .mini .not-mini .table-status.status-7{
    border: #ff0000 3px solid;
    color: #ff0000;
    background: #ff000030;
}
.table-status.status-3, .mini .not-mini .table-status.status-3,
.table-status.status-5, .mini .not-mini .table-status.status-5{
    border: #a3a2a2 3px solid;
    color: #a3a2a2;
    background: #a3a2a230;
}
.table-status.status-4, .mini .not-mini .table-status.status-4{
    border: #ff0000 3px solid;
    color: #ff0000;
    background: #ff000030;
}