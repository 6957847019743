.link{
    height: 100%;
    width: 100%;
    position: relative;
}
.link:before {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-color: #01acf0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-size: 18px;
    background-position: center;
    cursor: pointer;
    margin: auto;
    top: calc(50% - 12px);
    position: relative;
}
.link-copy{
    z-index: 99999999;
    width: max-content;
    padding: 8px;
    background: #333333;
    border: 4px solid #01acf0;
    color: #fff;
    border-radius: 10px;
    position: absolute;
    grid-template-columns: max-content 30px;
    display: none;
    top: 50px;
}
.link-copy::after{
    bottom: 102%;
    left: 6px;
    content: "";
    position: absolute;
    pointer-events: none;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #01acf0;
    border-width: 15px;
}
.link:hover .link-copy{
    display: grid;
}
.link-copy input {
    background: unset;
    border: unset;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}
.link-copy-btn{
    background-size: 25px 25px;
    height: 25px;
    width: 25px;
    transition: all 200ms;
    cursor: pointer;
}
.link-copy-btn:hover{
    background-size: 20px 20px;
}
.link-copy-btn.true{
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2048.09%2031.47%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3Anone%3Bstroke%3A%23fff%3Bstroke-miterlimit%3A10%3Bstroke-width%3A8px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Ev%3C%2Ftitle%3E%3Cline%20class%3D%22a%22%20x1%3D%221.49%22%20y1%3D%2214.68%22%20x2%3D%2215.23%22%20y2%3D%2229.95%22%2F%3E%3Cline%20class%3D%22a%22%20x1%3D%2246.8%22%20y1%3D%221.53%22%20x2%3D%2213.23%22%20y2%3D%2229.95%22%2F%3E%3C%2Fsvg%3E');
    position: relative;
}
.link-copy-btn[image="v"]::before{
    content: attr(data-title);
    position: absolute;
    font-size: 11px;
    color: #fff;
    bottom: 0;
}