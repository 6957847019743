.editable-div {
    position: relative;
    cursor: pointer;
    width: 90%;
    min-height: 20px;
}
.editable-div:hover {
    background-size: 14px;
    background-position: right;
}
textarea.editable-textarea {
    -webkit-writing-mode: unset;
    text-rendering: unset;
    color: unset;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    background-color: unset;
    resize: none;
    margin: auto;
    font: unset;
    border-width: 0;
    outline: none;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
}
.editable-div-edit {
    position: relative;
    width: 90%;
}
.editable-div-edit:after{
    content: '';
    position: absolute;
    border-bottom: solid 2px #01acf1 !important;
    right: 0;
    left: 0;
    bottom: 5px;
    width: 90%;
}
