.table-action {
    height: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 50px 80px repeat(5, 50px);
    font-size: 18px;
    text-align: center;
}
.table-action .text{
    margin: auto;
}