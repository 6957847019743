.switcher-button {
    width: 180px;
    height: 30px;
    display: inline-block;
    position: relative;
    margin: 0 20px;
}
.switcher-button-option {
    border-radius: 20px;
    border: 1px solid #01acf1;
    background: #f9f9f9;
    height: 30px;
    position: relative;
    width: 100%;
    margin: auto;
    cursor: pointer;
    transition: all 0.4s;
    display: grid;
    grid-template-columns: 70px 40px 70px;
    text-align: center;
    line-height: 28px;
}
.switcher-button-option[option="1"], .switcher-button-option[option="2"] {
    grid-template-columns: 70px 40px 70px;
}
.switcher-button-option .option-image {
    border-radius: 50%;
    height: 22px;
    width: 22px;
    background-color: #01acf1;
    transition: all 0.4s;
    background-size: 60%;
    background-position: center center;
    padding: 5px 10px;
    margin: auto;
    top: 3px;
    position: relative;
    display: inline-block;
}
.switcher-button-option .option-1, .switcher-button-option .option-2 {
    border-radius: 0 10px 10px 0;
    height: 22px;
    position: relative;
    top: 3px;
    line-height: 23px;
}
.switcher-button-option .option-1 {
    border-radius: 10px 0 0 10px;
    margin-left: 4px;
}
.switcher-button-option .option-2 {
    border-radius: 0 10px 10px 0;
    margin-right: 4px;
}
.switcher-button-option[option="1"] .option-image {
    border-radius: 0 10px 10px 0;
}
.switcher-button-option[option="2"] .option-image {
    border-radius: 10px 0 0 10px;
}
.switcher-button-option[option="1"] .option-1 {
    background-color: #01acf1;
    color: #fff;
}
.switcher-button-option[option="2"] .option-2 {
    background-color: #01acf1;
    color: #fff;
}
.switcher-button-option[option="1"] .option-0 {
    direction: ltr;
}
.switcher-button-option[option="2"] .option-0 {
    direction: rtl;
}
.switcher-button-option .option-0 span {
    position: relative;
    top: -2px;
    padding: 0 4px;
    color: #707070;
    opacity: 0.31;
}