.table-row-all{
    height: 100%;
    overflow: hidden;
}
.table-row-details {
    height: calc(100% - 60px);
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    overflow: hidden;
}
.table-row-all-title {
    display: grid;
    grid-template-columns: 30% 20% 50%;
    overflow: hidden;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #c8c8c8;
    border-top: 1px solid #c8c8c8;
}

.col-details-row {
    display: grid;
    grid-template-columns: 40% 60%;
    margin-bottom: 12px;
    font-size: 15px;
}
.col-details-title, .t-r-d-a-title {
    font-size: 15px;
    font-weight: 700;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 65% 35%;
}
.table-row-details-details{
    height: 100%;
    border-right: 1px solid #c8c8c8;
    overflow: hidden;
}
.t-r-d-a-all {
    height: 100%;
    overflow-x: hidden;
}
.table-row-details-all {
    height: 100%;
    overflow: hidden;
}
.table-row-title {
    height: 180px;
    width: 100%;
    transition: all 0.5s;
}
.table-row-title-bottom {
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    text-align: center;
    margin: auto;
    height: 60px;
    width: 100%;
    line-height: 63px;
}
.table-row-title-bottom > div {
    background-size: 25px;
    cursor: pointer;
    transition: all 0.5s;
}
.table-row-title-image {
    width: 100%;
    height: 65px;
    margin: 0 auto;
    background-size: 50px;
    position: relative;
    display: inline-block;
    transition: all 0.5s;
}
.table-row-title-text {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    padding: 0 0 20px;
    transition: all 0.5s;
}
.table-row-title-main {
    height: 120px;
    transition: all 0.5s;
}
.table-row-all-title-close {
    background-position: left+20px center;
    background-size: 12px;
    cursor: pointer;
}
.table-row-all-title-title {
    line-height: 60px;
    font-weight: 700;
}
.table-details-rows {
    height: calc(100% - 240px);
    padding: 0 10px;
    transition: all 0.5s;
}
.table-row-all-title-action {
    display: grid;
    grid-template-columns: calc(100% - 60px) 30px 30px;
}
.table-row-action {
    cursor: pointer;
}
.table-row-action[type="settings"] {
    background-size: 5px;
}
.table-row-action[type="export"] {
    background-size: 15px;
}
.table-row-action[type="search"] {
    display: grid;
    grid-template-columns: calc(100% - 10px) 10px;
    height: 100%;
}
.table-row-action[type="search"].opened {
    display: grid;
    grid-template-columns: 20px calc(100% - 20px);
}
.table-row-action[type="search"].opened > div {
    border-bottom: 1px solid #a7a6a6;
    height: 30px;
    margin: auto 0;
    background-position: left;
}
.table-row-action[type="search"] > div {
    background-size: 20px;
    background-position: right;
    transition: all 1s;
}
.table-row-action[type="search"] > div[image="close"]{
    background-size: 10px;
}
.table-row-action[type="searchInput"] {
    width: 0;
    opacity: 0;
    transition: all 1s;
}
.table-row-action[type="search"].opened .table-row-action[type="searchInput"] {
    font-size: 18px;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    color: #474747;
    border-bottom: 1px solid #a7a6a6;
    height: 30px;
    margin: auto 0;
    width: 100%;
    opacity: 1;
    transition: all 1s;
}
.col-details-row-value.bold{
    font-weight: 700;
}
.table-row-details-details.isScroll .table-row-title-main{
    height: 30px;
}
.table-row-details-details.isScroll .table-row-title-image {
    top: 3px;
    height: 15px;
    width: 40px;
    background-size: 15px;
}
.table-row-details-details.isScroll .table-row-title-text {
    font-size: 12px;
    padding: 0;
    display: inline-block;
    line-height: 30px;
    width: 215px;
}
.table-row-details-details.isScroll .table-row-title-bottom {
    height: 30px;
    line-height: 30px;
}
.table-row-details-details.isScroll .table-row-title{
    height: 60px;
}
.table-row-details-details.isScroll .table-details-rows{
    height: calc(100% - 150px);
}

@media screen and (max-width: 1024px) {
    .table-row-details {
        position: absolute;
        top: 0;
        height: 100%;
    }
}