.checkbox-con {
    height: 100%;
    width: 100%;
    position: relative;
}
.checkbox{
    width: 16px;
    height: 16px;
    margin: auto;
    border-radius: 63%;
    cursor: pointer;
    box-shadow: rgba(50,50,50,.05) 0 4px 4px;
    background-color: #ffffff;
    position: relative;
    transition: all 1s;
    border: 1px solid #c8c8c8;
    top: calc(50% - 8px);
    text-align: center;
    z-index: 1;
}
.checkbox.selected {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0 -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: #99a1a7;
}
.checkbox:after {
    content: '';
    position: absolute;
    background: #01acf0;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    top: 3px;
    left: 3px;
    right: 3px;
    transition: all 0.5s;
    opacity: 0;
}
.checkbox.selected:after {
    opacity: 1;
    transition: all 0.5s;
}
.checkbox:before {
    content: '\2714';
    font-size: 11px;
    position: absolute;
    top: 0;
    left: 3px;
    right: 3px;
    color: #01acf0;
    opacity: 0;
    transition: all 0.5s;
}
.checkbox.process-success:before {
    opacity: 1;
    transition: all 0.5s;
}
