.table-loading{
    /*backdrop-filter: blur(12px);*/
    /*border: 1px solid #c8c8c8;*/
    /*border-radius: 10px;*/
    height: 60px;
    cursor: pointer;
    display: grid;
    margin-top: 3px;
    background: #f1f1f1;
    max-width: 100%;
    position: relative;
    -webkit-animation: glow linear 2s infinite;
    animation: glow linear 2s infinite;
}
@-webkit-keyframes glow {
    0% { background-color:#f1f1f1; }
    50% { background-color:#f1f1f16e; }
    100% { background-color:#f1f1f1; }

}
@keyframes glow {
    0% { background-color:#f1f1f1; }
    50% { background-color:#f1f1f16e; }
    100% { background-color:#f1f1f1; }
}