.mailing-table-hover {
    position: absolute;
    height: 110px;
    width: 145px;
    background: #ffffff;
    top: -95px;
    display: none;
    padding: 10px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    font-size: 16px;
    z-index: 1;
}
.table-row:nth-child(1) .mailing-table-hover, .table-row:nth-child(2) .mailing-table-hover {
    top: 45px;
}
.m-t-h-row{
    display: grid;
    grid-template-columns: 40px 60px 40px;
}
.mailingStatusHover:hover .mailing-table-hover {
    display: block;
}
.m-t-h-icon {
    background-position: left;
}
.m-t-h-icon[image="fails"] {
    background-size: 25px;
    background-position: 20px;
}
.mailing-table-hover:before {
    content: '';
    position: absolute;
    background: #ffffff;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    bottom: -5px;
    right: 10px;
    z-index: 9;
    border-bottom: 1px solid #c8c8c8;
    border-left: 1px solid #c8c8c8;
}
.table-row:nth-child(1) .mailing-table-hover:before, .table-row:nth-child(2) .mailing-table-hover:before {
    bottom: 102px;
    border-top: 1px solid #c8c8c8;
    border-left: 1px solid #c8c8c8;
    border-bottom: none;
    border-right: none;
}
.m-t-c-icon {
    height: 100%;
    width: 100%;
    background-size: 55%;
}