.m-t-c-input input {
    border: unset;
    border-bottom: 1px solid #b7b5b5;
    margin-left: 10px;
}
.m-t-c-input {
    position: absolute;
    height: 190px;
    width: 180px;
    z-index: 1;
    background: #ffffff;
    padding: 10px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    text-align: center;
    top: 40px;
    margin-left: -10px;
    display: none;
}
.m-t-c-input.show {
    display: block;
}
.m-t-c-input input, .m-t-c-title {
    margin-bottom: 15px;
}
.m-t-c-input .button {
    display: block;
    right: 50px;
}
.m-t-c-input:before{
    content: '';
    position: absolute;
    background: #ffffff;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    left: 10px;
    z-index: 9;
    top: -5px;
    border-top: 1px solid #c8c8c8;
    border-left: 1px solid #c8c8c8;
}
.m-t-c-close {
    height: 12px;
    background-size: 12px;
    width: 12px;
    margin: 2px 0;
    border-radius: 50%;
    border: 1px solid #01acf1;
    padding: 10px;
}