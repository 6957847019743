.svg {
    display: block;
    max-width: 100%;
}
.svg-circle-bg {
    fill: none;
}

.svg-circle {
    fill: none;
}
.svg-circle-text {
    font-size: 2rem;
    text-anchor: middle;
    fill: #fff;
    font-weight: bold;
}
.progress-bar {
    position: absolute;
    top: 16px;
    right: auto;
    left: 12px;
}