.table-row {
    border: 1px solid #E5E5E5;
    /*border-radius: 10px;*/
    height: 60px;
    cursor: pointer;
    display: grid;
    /*margin-top: 3px;*/
    background: #FAFAFA;
    max-width: 100%;
    padding-right: 0;
    transition: max-width 1s, border-width 0s, padding-right .3s linear;
    position: relative;
    box-sizing: border-box;
}
.mini .table-row {
    max-width: 400px;
}
.mini .not-mini .table-row {
    max-width: 100%;
    font-size: 12px;
}
.table-row:hover, .table-row.selected{
    background: #ffffff;
    border-top: 2px solid #01acf1;
    border-bottom: 2px solid #01acf1;
    padding-left: 50px;
    transition: padding-left .3s linear;
}
.mini .table-row:hover, .mini .table-row.selected {
    padding-right: 0;
}
.table-row .column-icon{
    background-size: 40%;
}
.table-row:hover .table-column.border{
    border-right: 1px solid #01acf1;
}
.table-row-process-success{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}
.table-row-process-success:after {
    content: '\2714';
    font-size: 11px;
    position: relative;
    color: #01acf0;
    z-index: 9999999;
    top: calc(50% - 7px);
    left: calc(50% - 4px);
    right: calc(50% - 4px);
}

.table-row-sort .letter {
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    line-height: 47px;
    vertical-align: middle;
}
.table-row-sort .letter:before {
    content: '';
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 2px solid #c8c8c8;
    right: calc(50% - 13px);
    left: calc(50% - 13px);
    top: calc(50% - 13px);
}
.image-above-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #a3be3b;
    background-size: 14px;
    top: 4px;
}
.table-column.column-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.table-column.column-textBold{
    font-weight: 700;
    height: 60px;
    line-height: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.table-column.column-button{
    margin: auto;
}
.table-column.border{
    border-right: 1px solid #c8c8c8;
    border-left: none;
}
.table-column.column-checkbox {
    position: relative;
    left: -50px;
    width: 50px;
}
.table-column.column-checkbox > div {
    height: 100%;
}
.table-row:not(:hover) .table-column.column-checkbox.border{
    border: none;
}
.table-column {
    padding: 0 5px;
    overflow: hidden;
    width: 100%;
    /*position: relative;*/
}
.table-column:not(.column-icon):not(.column-checkbox):not(.column-iconV){
    margin: auto 0;
    overflow: hidden;
}
.table-column.column-status {
    margin: auto;
    width: 120px;
}
.mini .table-column.column-status {
    margin: auto 0;
    width: 20px;
    padding: 0;
}
.mini .not-mini .table-column.column-status {
    margin: auto 0;
    width: 90px;
}
.table-column.second-row {
    margin: 0;
    line-height: 20px;
    padding-top: 10px;
}
.table-column.second-row .column-second-row {
    display: block;
    font-weight: 400;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.table-column.column-icon[image="settings"] {
    background-size: 5px;
}
@media screen and (max-width: 1024px) {
    .mini *:not(.not-mini) .table-row {
        max-width: 1024px;
    }
}