.table {
    height: calc(100% - 100px);
}
.table.not-mini {
    height: 100%;
}
.table-content {
    position: relative;
    display: grid;
    grid-template-columns: 80% 0% 20%;
    height: 100%;
    padding-top: 100px;
}
.mini .table-content{
    grid-template-columns: 20% 60% 20%;
}
.mini .not-mini .table-content{
    grid-template-columns: 100%;
    height: 100%;
    padding-top: 0;
}
.table-header {
    background: #ffffff;
    position: fixed;
    height: 100px;
    z-index: 5;
    width: 100%;
    padding: 0 20px;
}
.table-statistics {
    height: 100%;
    border-left: 1px solid #c8c8c8;
}
.table-rows{
    width: 100%;
    max-width: 100%;
    transition: all 0.4s;
    display: inline-block;
    overflow: hidden;
}
.table-all-rows {
    height: 100%;
    overflow-x: hidden;
}
.mini .not-mini .table-rows{
    max-width: 100%;
}

@media screen and (max-width: 1024px) {
    .mini > .table-content{
        display: block;
    }
    .mini *:not(.not-mini) .table-content{
        max-width: 1024px;
    }
}