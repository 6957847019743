.button{
    border: #01acf1 1px solid;
    color: #01acf1;
    border-radius: 30px;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    padding: 0 10px;
    transition: all 0.5s;
    position: relative;
    width: max-content;
    text-align: center;
    font-weight: 600;
    display: inline-block;
}
.button[colorButton="01acf1"]{
    color: #ffffff;
    background-color: #01acf1;
    border: #ffffff 1px solid;
}
.button[colorButton="7f9810"]{
    color: #ffffff;
    background-color: #7f9810;
    border: #ffffff 1px solid;
}
.button[colorButton="ff0000"]{
    color: #ffffff;
    background-color: #ff0000;
    border: #ffffff 1px solid;
}
.button[colorButton="474747"]{
    color: #474747;
    background-color: #ffffff;
    border: #474747 1px solid;
}
.button[colorButton="f1e028"]{
    color: #000000;
    background-color: #f1e028;
    border: none;
}
.button span{
    position: relative;
}
.button[image]{
    position: relative;
    background-size: 20px 20px;
    padding-left: 30px;
    padding-right: 10px;
    background-repeat: no-repeat;
    background-position: 5px center;
}
.button-error {
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: red;
    top: -18px;
    right: 5px;
    left: auto;
}