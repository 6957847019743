.table-filter{
    height: 40px;
    width: 130px;
    color: #ffffff;
    background: #000000;
    border-radius: 20px;
    display: inline-block;
    margin: 20px;
}
.table-filter-items{
    display: grid;
    grid-template-columns: 30% 50% 20%;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.table-filter-list {
    background-size: 12px;
}
.table-filter-icon {
    background-size: 30px;
}
.table-filter-text {
    margin: auto 0;
    font-size: 18px;
}